import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";

export default function Home() {

    return (
        <HelmetProvider>

            <Helmet>
                <title>{`Portfolio | ${process.env.REACT_APP_NAME}`}</title>
                <meta name="description" content="You can find a list of projects I've worked on here."/>
            </Helmet>

            <Container className={"container-small"}>
                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        things I've worked on
                    </h1>

                    <p>
                        I've worked on a variety of projects over the years. Here are some of the highlights.
                    </p>

                    <hr/>

                    <div>
                        <h2 className={"h2"}><a href="https://maileroo.com" target="_blank" className="link">Maileroo</a></h2>
                        <p className="pt-xl-1 small">Startup (2024)</p>
                        <p>Think of Maileroo as Clanica 2.0. It is better, faster and packed with more features. Maileroo is a robust email delivery service, equipped with an easy to use SMTP service and Email API, enabling you and your business to send transactional emails.</p>
                    </div>

                    <br/>

                    <div>
                        <h2 className={"h2"}>Zabhost</h2>
                        <p className="pt-xl-1 small">Startup (2023)</p>
                        <p>Zabhost is a robust platform designed to provide unparalleled managed WordPress hosting solutions. It empowers businesses and individuals to unlock the true potential of their websites. With Zabhost, users can experience blazing-fast speeds, ironclad security, and globally distributed datacenters, all while simplifying website management with automated processes and superior support.</p>
                    </div>

                    <br/>

                    <div>
                        <h2 className={"h2"}>Zeruh</h2>
                        <p className="pt-xl-1 small">Startup (2023)</p>
                        <p>Zeruh is a comprehensive solution that empowers businesses to create and manage their own knowledgebase, self-service portal, and help centers. With Zeruh, companies can streamline their customer support processes, enhance user experience, and provide efficient resources for their customers.</p>
                    </div>

                    <br/>

                    <div>

                        <h2 className={"h2"}><a href="https://github.com/s3827035/sepm-7b-wordle" target="_blank" className="link">SEPM 7B Wordle</a></h2>
                        <p className="pt-xl-1 small">School Project (2022)</p>
                        <p>A group project in 2022 to create a Wordle clone. Not complete, filled with numerous bugs. Done in collaboration with Patrick, Richard, Vincent, Joe and Lachlan.</p>
                    </div>

                    <br/>

                    <div>
                        <h2 className={"h2"}>Clanica</h2>
                        <p className="pt-xl-1 small">Startup (2021)</p>
                        <p>Clanica is a robust email delivery service, equipped with an easy to use SMTP service and Email API, enabling you and your business to send all kinds of emails, from transactional to newsletter and marketing emails.</p>
                    </div>

                    <br/>

                    <div>
                        <h2 className={"h2"}><a href="https://github.com/areebmajeed/erb-smtpd" target="_blank" className="link">Erb SMTPd</a></h2>
                        <p className="pt-xl-1 small">Opensource (2021)</p>
                        <p>Erb SMTPd is a multi-worker SMTP server written in PHP. It has support for plain text, Opportunistic TLS (STARTTLS) and Implicit TLS communication.</p>
                    </div>

                    <br/>

                    <div>
                        <h2 className={"h2"}><a href="https://coinchaser.io/" target="_blank" className="link">CoinChaser</a></h2>
                        <p className="pt-xl-1 small">Side Project (2020)</p>
                        <p>CoinChaser is a Bitcoin and cryptocurrency portfolio tracking software. It can be used to track your coins, tokens, and assets all in one place. CoinChaser gives you an overview of your holdings one place.</p>
                    </div>

                    <br/>

                    <div>
                        <h2 className={"h2"}><a href="https://github.com/areebmajeed/ether-forwarder" target="_blank" className="link">Ethereum Forwarder</a></h2>
                        <p className="pt-xl-1 small">Opensource (2019)</p>
                        <p>You can deploy this contract to forward the Ethereum from your smart contract address to your master address without any input. You can also use this accept ERC 20 tokens and send them on demand.</p>
                    </div>

                    <br/>

                    <div>
                        <h2 className={"h2"}><a href="https://coinmarketalert.com/" target="_blank" className="link">CoinMarketAlert</a></h2>
                        <p className="pt-xl-1 small">Freelance (2017)</p>
                        <p>CoinMarketAlert (CMA) is a cryptocurrency price alert system. CMA offers various level and type of alerts which help you make a profit and secure your investment by notifying you of every movement that sets your investments at a loss.</p>

                    </div>

                    <br/>

                    <div>
                        <h2 className={"h2"}>Rapid2FA</h2>
                        <p className="pt-xl-1 small">Startup (2017)</p>
                        <p>Rapid2FA was an API service provider for businesses and enterprises to implement 2FA on their website without writing any code. Rapid2FA supported SMS OTP, Email OTP, traditional TOTP and had support for backup codes.</p>
                    </div>

                </div>
            </Container>
        </HelmetProvider>
    );

}